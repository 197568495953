.animate__animated.animate__slideInLeft {
    --animate-duration: 3s;
  }


  .box {
    width: 100%;
    overflow: hidden;
  }

  .slide-box {
    top: 100%;
    position: relative;
    transition: all .5s ease-out;
    /* background: #428bca; */
    background-image: url('../assets/images/blog-card-image1.svg');
    height: 100%;
  }
  
  .box:hover > .slide-box{
    top: 0;
  }

   