.slide {
  flex: 0 0 100%;
  transition: opacity linear 0.3s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.slide.previous,
.slide.next {
  opacity: 0;
}
