@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-slide {
  flex: 1;
  height: 100%;
  position: relative;
  width: 100% !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide div div {
  width: 100%;
}

.slick-slide div div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-content {
  position: absolute;
  top: 45%;
}

.slick-dots {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  display: block;
  top: 32%;
  left: 95%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  content: url("data:image/svg+xml; utf8, <svg width='10' height='10' viewBox='0 0 10 10' fill='white' xmlns='http://www.w3.org/2000/svg'><circle cx='5' cy='5' r='5' transform='rotate(90 5 5)' fill='white'/></svg>");
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: url("data:image/svg+xml; utf8, <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='5' cy='5.75' r='4.5' transform='rotate(90 5 5.75)' stroke='white'/></svg>");
  text-align: center;
  opacity: 2.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: auto;
  margin-left: auto;
}

.rec.rec-slider-container {
  overflow: visible;
}

@media screen and (max-width: 767px) {
  .slick-dots {
    display: flex !important;
    flex-direction: row;
    position: absolute;
    display: block;
    top: 92%;
    left: 75%;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
