@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/animation.css';

.svg {
  @apply w-full h-full object-contain;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'PoppinsBlack';
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf') format('opentype');
}
@font-face {
  font-family: 'PoppinsLight';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'RobotoBlack';
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('opentype');
}
@font-face {
  font-family: 'RobotoLight';
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'RobotoMedium';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('opentype');
}
