.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text-slider {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  transition: transform 0.3s ease;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.group:hover svg {
  color: white !important;
  fill: white !important;
}
.text-height-max {
  line-height: 3.75rem !important;
}

.react-tel-input .form-control {
  border-radius: 12px!important;
  height: 48px !important;
  width: 100%!important;
  border-color: rgb(6 0 27 / var(--tw-border-opacity))!important;
  background: none!important;
}

.react-tel-input:active .form-control:focus{
  border-color:#120051!important;
}

.react-tel-input .flag-dropdown {
  border-color: rgb(6 0 27 / var(--tw-border-opacity))!important;
  border-radius: 12px 0 0 12px!important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 12px 0 0 12px!important;
}

.react-tel-input .selected-flag {
  border-radius: 12px 0 0 12px!important;
}

@media screen and (max-width: 480px) {
  .text-height-max {
    line-height: 1.75rem !important;
  }
}
