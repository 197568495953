.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #06001b;
}

.breadcrumb-item:not(:last-child)::after {
  content: '>';
  margin: 0 0.5rem;
  color: #777;
}
