.slider__line {
  animation: sld 3s linear infinite;
  transform: translateY(0%);
  transition: all 9s ease-in-out;
}

.slider__lines {
  animation: slds 3s linear infinite;
  transform: translateY(-120%);
  transition: all 9s ease-in-out;
}

@keyframes sld {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-120%);
  }
}

@keyframes slds {
  from {
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 1024px) {
  .slider__lines {
    animation: slds 3s linear infinite;
    transform: translateX(0%);
    transition: all 9s ease-in-out;
  }
  @keyframes slds {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-230%);
    }
  }
}
