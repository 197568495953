.carouselContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 4%;
}

.carouselContainer p:nth-child(1) {
  font-style: normal;
  font-weight: bold;
  line-height: 60px;
  text-align: left;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  width: 63%;
}

.scrollDownContainer {
  border-radius: 12px;
  width: 152px !important;
  margin: auto;
  top: 55%;
  cursor: pointer;
}

.scrollDownIcon {
  width: 24px !important;
  height: 24px !important;
}

@media screen and (max-width: 767px) {
  .carouselContainer p:nth-child(1) {
    font-size: 20px;
    line-height: 30px;
    width: 85%;
    text-align: left;
    font-size: 24px;
    margin-top: -20%;
  }
  .scrollDownContainer {
    border-radius: 12px;
    width: 152px !important;
    margin: auto;
    top: 70%;
    cursor: pointer;
    font-size: 16px;
  }
}
